import axios from "axios";
import config from "./config";

axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        removeToken();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
);

export async function fetchExampleData() {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${config.API_IP}/api/example`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function removeToken() {
  localStorage.removeItem("token");
}
