import { createStore } from "vuex";

export default createStore({
  state: {
    rail: false,
  },
  getters: {
    railState: (state) => state.rail,
  },
  mutations: {
    toggleRail(state) {
      state.rail = !state.rail;
    },
  },
  actions: {
    toggleRail({ commit }) {
      commit("toggleRail");
    },
  },
  modules: {},
});
