import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { mask } from "vue-the-mask";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import "vuetify/dist/vuetify.min.css";

import { VBtn, VMenu, VApp, VAlert, VTextField, VMain, VForm, VNavigationDrawer, VCard, VTooltip, VDivider } from 'vuetify/components';
import * as VCards from 'vuetify/components/VCard';
import * as VAppBar from 'vuetify/components/VAppBar';
import * as VGrid from 'vuetify/components/VGrid';
import * as VList from 'vuetify/components/VList';
import * as directives from "vuetify/directives";

import * as VComps from "vuetify/components";

import apiClient, { checkToken } from './axios';
import "@mdi/font/css/materialdesignicons.css";

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const vuetify = createVuetify({
  components: {
    VBtn,
    VMenu,
    VApp,
    VAlert,
    VTextField,
    VMain,
    VForm,
    VNavigationDrawer,
    VCard,
    VTooltip,
    VDivider,
    ...VCards,
    ...VAppBar,
    ...VGrid,
    ...VList,
    ...VComps
  },
  directives,
});

const app = createApp(App);

app.config.globalProperties.$axios = apiClient;

router.beforeEach(async (to, from, next) => {
  console.log('Starting token check...');
  if (to.name !== 'Login' && to.name !== 'Registration') {
    await checkToken();
  }
  next();
});

app.use(router).use(store).use(vuetify).directive('mask', mask).mount('#app');