<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card
          v-if="successMessage"
          text="{{ successMessage }}"
          variant="tonal"
        ></v-card>

        <div class="login-container">
          <v-card elevation="3" class="login-card animated-form">
            <v-card-text>
              <h4>Добро пожаловать в Linkachu! 👋</h4>
              <p>
                Пожалуйста, войдите в свою учетную запись и начните SEO
                приключение
              </p>
            </v-card-text>
            <v-card-text>
              <v-form ref="form" @submit="login">
                <v-text-field
                  v-model="username"
                  class="custom-input"
                  :rules="usernameRules"
                  label="Ваш EMAIL"
                  variant="solo"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  class="custom-input"
                  :rules="passwordRules"
                  label="Пароль"
                  type="password"
                  variant="solo"
                ></v-text-field>
                <v-btn prepend-icon="mdi-check" type="submit"> Войти </v-btn>
                <router-link to="/registration" class="pl-5"
                  ><v-btn variant="text"> Регистрация </v-btn></router-link
                >
                <div v-if="successMessage" class="success-message">
                  {{ successMessage }}
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </div>

        <transition-group name="fade">
          <v-alert
            v-for="(alert, index) in errorAlerts"
            :key="index"
            v-model="alert.visible"
            border="start"
            variant="tonal"
            closable
            close-label="Закрыть"
            color="#C51162"
            title="Ошибка"
            class="error-alert"
          >
            {{ alert.message }}
          </v-alert>
        </transition-group>

        <transition-group name="fade">
          <v-alert
            v-for="(alert, index) in successAlerts"
            :key="index"
            v-model="alert.visible"
            border="start"
            variant="tonal"
            closable
            close-label="Закрыть"
            color="success"
            title="Успешный вход"
            class="success-alert"
          >
            {{ alert.message }}
          </v-alert>
        </transition-group>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import router from "../router";
import "@/assets/global.css";
import "@/assets/page-auth.css";
import config from "../config";

export default {
  data() {
    return {
      username: "",
      password: "",
      successMessage: "",
      usernameRules: [
        (v) => !!v || "Имя пользователя обязательно к заполнению",
      ],
      passwordRules: [(v) => !!v || "Пароль обязателен к заполнению"],
      errorAlerts: [],
      successAlerts: [],
    };
  },
  mounted() {
    this.successMessage = this.$route.query.successMessage || "";
    this.animateForm();
  },
  methods: {
    login(event) {
      event.preventDefault();

      if (this.$refs.form.validate()) {
        axios
          .post(
            `${config.API_IP}/api/login`,
            {
              email: this.username,
              password: this.password,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            localStorage.setItem("token", response.data.access_token);
            router.push("/home");
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              const errorMessage = "Неверный логин или пароль!";
              this.errorAlerts.push({ message: errorMessage, visible: true });

              setTimeout(() => {
                this.closeAlert(this.errorAlerts.length - 1);
              }, 2000); // Скрыть сообщение об ошибке через 2 секунды
            } else {
              const errorMessage =
                "Сервер временно недоступен, попробуйте позже!";
              this.errorAlerts.push({ message: errorMessage, visible: true });

              setTimeout(() => {
                this.closeAlert(this.errorAlerts.length - 1);
              }, 2000);
              //console.error(error);
            }
          });
      }
    },
    closeAlert(index) {
      if (this.errorAlerts[index]) {
        this.errorAlerts.splice(index, 1);
      }
      if (this.successAlerts[index]) {
        this.successAlerts.splice(index, 1);
      }
    },
    animateForm() {
      const form = document.querySelector(".animated-form");
      if (form) {
        form.classList.add("run-animation");
      }
    },
  },
};
</script>

<style>
@import "~@mdi/font/css/materialdesignicons.css";

/* Определение анимации */
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Применение анимации к классу run-animation */
.animated-form.run-animation {
  animation: fadeInUp 0.5s ease forwards;
}

.custom-input .v-field__outline {
  border-color: #d9dee3; /* замените #ВАШ_ЦВЕТ на нужный цвет */
}

.custom-input .v-field__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  color: #697a8d;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.53;
  padding: 0.4375rem 0.875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  min-height: 45px;
}

.v-input--density-default.custom-input
  .v-field--variant-solo
  .v-label.v-field-label--floating {
  top: -10px;
}
</style>
