<template>
  <div id="app">
    <!-- Проверяем, авторизован ли пользователь -->
    <div v-if="!isAuthenticated">
      <!-- Компонент страницы авторизации/регистрации -->
      <AuthPage></AuthPage>
    </div>
    <div v-else>
      <v-app>
        <HorisontalMenu />
        <SidebarMenu></SidebarMenu>
        <div class="content" :style="contentStyle">
          <router-view></router-view>
        </div>
        <footer>
          <!-- Глобальный компонент подвала сайта -->
        </footer>
      </v-app>
    </div>
  </div>
</template>

<script>
//import SidebarMenu from '/src/components/SidebarMenu.vue';
import AuthPage from "/src/components/AuthPage.vue";
import HorisontalMenu from "./components/HorisontalMenu.vue";
import SidebarMenu from "./components/SidebarMenu.vue";

export default {
  components: {
    SidebarMenu,
    AuthPage,
    HorisontalMenu,
  },
  data() {
    return {
      // Статус авторизации
      isAuthenticated: false,
    };
  },
  computed: {
    contentStyle() {
      // Доступ к состоянию `rail` из Vuex store
      const isRailActive = this.$store.getters.railState;
      return {
        marginLeft: isRailActive ? "82px" : "250px",
        marginTop: "84px",
        transition: "margin-left 0.2s ease-in-out",
        padding: "0 20px 0 0",
      };
    },
  },
  // Если вы хотите обновлять состояние при изменении маршрутов
  watch: {
    $route: function () {
      this.isAuthenticated = !!localStorage.getItem("token");
    },
  },
  mounted() {
    // Проверяем токен при монтировании приложения
    this.isAuthenticated = !!localStorage.getItem("token");
  },
};
</script>

<style>
/* Стили для общего макета */
</style>
