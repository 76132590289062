<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <div class="register-container">
          <v-card elevation="3" class="register-card animated-form">
            <v-card-text>
              <h4>SEO приключения начинаются здесь 🚀</h4>
              <p>
                Создайте свою учетную запись и начните управлять продвижением
                сайта
              </p>
            </v-card-text>
            <v-card-text>
              <v-form ref="form" @submit.prevent="register">
                <v-text-field
                  v-model="formData.email"
                  class="custom-input"
                  label="E-mail"
                  variant="solo"
                ></v-text-field>
                <v-text-field
                  v-model="formData.password"
                  class="custom-input"
                  label="Пароль"
                  type="password"
                  variant="solo"
                ></v-text-field>
                <v-text-field
                  v-model="formData.password_confirm"
                  class="custom-input"
                  label="Повторите пароль"
                  type="password"
                  variant="solo"
                ></v-text-field>
                <v-btn prepend-icon="mdi-check" type="submit"
                  >Зарегистрироваться</v-btn
                >
                <router-link to="/login" class="pl-5">
                  <v-btn variant="text">Войти</v-btn>
                </router-link>
                <v-alert
                  v-if="successMessage"
                  border="left"
                  color="success"
                  dark
                  class="success-alert"
                  transition="fade"
                  dismissible
                  :timeout="5000"
                  @dismissed="successMessage = ''"
                >
                  {{ successMessage }}
                </v-alert>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import router from "../router";
import "@/assets/global.css";
import config from "../config";

export default {
  setup() {
    const formData = ref({
      email: "",
      password: "",
      password_confirm: "",
    });

    const successMessage = ref("");

    const register = async () => {
      try {
        await axios.post(`${config.API_IP}/api/register`, {
          email: formData.value.email,
          password: formData.value.password,
          password_confirm: formData.value.password_confirm,
        });

        successMessage.value =
          "Вы успешно зарегистрировались! Пожалуйста, выполните вход.";

        // Сброс значений полей после успешной регистрации
        formData.value.password = "";
        formData.value.email = "";
        formData.value.password_confirm = "";

        router.push({
          path: "/login",
          query: { successMessage: successMessage.value },
        });
      } catch (error) {
        console.error(error);
      }
    };

    return {
      formData,
      successMessage,
      register,
    };
  },
  mounted() {
    this.animateForm();
  },
  methods: {
    animateForm() {
      const form = document.querySelector(".animated-form");
      if (form) {
        form.classList.add("run-animation");
      }
    },
  },
};
</script>

<style scoped>
@import "~@mdi/font/css/materialdesignicons.css";
.register-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 410px; /* Измените это значение на желаемый размер */
}
.register-card {
  width: 100%;
  height: 100%;
}
.logo-container {
  display: flex;
  justify-content: center;
  width: 165px;
  align-items: center;
  margin: 0 auto;
  padding: 30px 0;
}
.logo {
  width: 100px; /* Измените это значение на желаемый размер */
  height: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.error-alert {
  margin-bottom: 10px;
}

.success-alert {
  margin-top: 10px;
}

/* Определение анимации */
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Применение анимации к классу run-animation */
.animated-form.run-animation {
  animation: fadeInUp 0.5s ease forwards;
}

.custom-input .v-field__outline {
  border-color: #d9dee3; /* замените #ВАШ_ЦВЕТ на нужный цвет */
}

.custom-input .v-field__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  color: #697a8d;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.53;
  padding: 0.4375rem 0.875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  min-height: 45px;
}

.v-input--density-default.custom-input
  .v-field--variant-solo
  .v-label.v-field-label--floating {
  top: -10px;
}
</style>
