<template>
  <v-navigation-drawer
    v-model="drawer"
    class="sidebar-menu"
    :rail="rail"
    width="220"
  >
    <v-list density="compact" nav>
      <v-list-item
        to="/home"
        prepend-icon="mdi-home"
        title="Главная"
        value="home"
      ></v-list-item>
      <v-list-item
        to="/indexing"
        prepend-icon="mdi-google"
        title="Индексация Google"
        value="indexing"
      ></v-list-item>
      <v-list-item
        to="/indexnow"
        prepend-icon="mdi-link"
        title="IndexNow"
        value="indexnow"
      ></v-list-item>
      <v-list-item
        to="/dropdomain"
        prepend-icon="mdi-domain"
        title="Дроп домены"
        value="dropdomain"
      ></v-list-item>
      <v-list-item
        to="/pnb"
        prepend-icon="mdi-earth"
        title="PBN сервис"
        value="pnb"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { removeToken } from "@/api";
import router from "@/router";

export default {
  name: "SidebarMenu",
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    rail() {
      return this.$store.getters.railState;
    },
  },
  methods: {
    logout() {
      removeToken();
      router.push("/login");
    },
  },
};
</script>

<style>
.sidebar-menu {
  z-index: 999 !important;
  padding-top: 70px;
  border: 0 !important;
}

.v-list-item--variant-text .v-list-item__overlay {
  background: #ffb500 !important;
}

.mdi-sitemap::before {
  content: "\F04AA";
  color: #707a8a;
}

.v-list-item--density-compact.v-list-item--one-line {
  min-height: 50px !important;
}

.v-list-item--nav .v-list-item-title {
  font-size: 15px !important;
}

.v-navigation-drawer
  i.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default {
  margin-right: -23px;
}
</style>
