<template>
  <div class="auth-page">
    <component :is="currentComponent" />
  </div>
</template>

<script>
import LoginForm from "@/views/LoginForm.vue";
import RegistrationForm from "@/views/RegistrationForm.vue";

export default {
  components: {
    LoginForm,
    RegistrationForm,
  },
  computed: {
    currentComponent() {
      const path = this.$route.path;
      if (path === "/login/" || path === "/login") {
        return "LoginForm";
      } else if (path === "/registration/" || path === "/registration") {
        return "RegistrationForm";
      }
      return null; // На случай, если путь не соответствует ни одному из известных
    },
  },
};
</script>

<style>
.auth-page main {
  background: url("/src/assets/background.png");
}
</style>
