import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login/",
  },
  {
    path: "/login/",
    name: "Login",
    component: () => import("../views/LoginForm.vue"),
    meta: {
      title: "Войти в систему",
      requiresAuth: false,
    },
  },
  {
    path: "/indexnow/",
    name: "IndexNow",
    component: () => import("../views/IndexNow.vue"),
    meta: {
      title: "Индексация IndexNow",
      requiresAuth: true,
    },
  },
  {
    path: "/pnb/",
    name: "PNB",
    component: () => import("../views/PNBservicePage.vue"),
    meta: {
      title: "PBN сервис",
      requiresAuth: true,
    },
  },
  {
    path: "/pnb/:id/",
    name: "DomainDetail",
    component: () => import("../views/DomainDetailPage.vue"),
    props: true,
    meta: {
      title: "Детальная домена",
      requiresAuth: true,
    },
  },
  {
    path: "/dropdomain/",
    name: "DropDomain",
    component: () => import("../views/DropDomain.vue"),
    meta: {
      title: "Дроп домены",
      requiresAuth: true,
    },
  },
  {
    path: "/indexing/",
    name: "IndexingPage",
    component: () => import("../views/IndexingPage.vue"),
    meta: {
      title: "Индексация Google",
      requiresAuth: true,
    },
  },
  {
    path: "/home/",
    name: "HomePage",
    component: () => import("../views/HomePage.vue"),
    meta: {
      title: "Главная",
      requiresAuth: true,
    },
  },
  {
    path: "/profile/",
    name: "Profile",
    component: () => import("../views/ProfileMain.vue"),
    meta: {
      title: "Профиль",
      requiresAuth: true,
    },
  },
  {
    path: "/registration/",
    name: "Registration",
    component: () => import("../views/RegistrationForm.vue"),
    meta: {
      title: "Регистрация в системе",
      requiresAuth: false,
    },
  },
  {
    path: "/admin/",
    name: "Admin",
    component: () => import("../views/AdministratorAdmin.vue"),
    meta: {
      title: "Система администрирования",
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Обновляем заголовок страницы при каждом переходе
  document.title = to.meta.title || "Мое приложение";

  const isAuthenticated = !!localStorage.getItem("token");

  // Проверяем, требует ли маршрут авторизации
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "Login" });
  } else if (
    (to.name === "Login" || to.name === "Registration") &&
    isAuthenticated
  ) {
    // Если пользователь авторизован и пытается перейти на страницу входа или регистрации,
    // перенаправляем его на страницу /sitemap
    next({ name: "HomePage" });
  } else {
    // В противном случае пропускаем пользователя дальше
    next();
  }
});

export default router;
